<template>
  <v-sheet>
    <Dialog :dialog="recordPaymentDialog" card-text-class="px-4 py-0" :dialogWidth="1200">
      <template v-slot:title>
        <span>Record Payment for {{ bills.bill_number }}</span> <v-spacer></v-spacer
      ></template>
      <template v-slot:body>
        <v-container>
          <template>
            <v-layout class="bg_grey pt-0 pb-0 px-0 my-0">
              <v-row>
                <v-col v-if="total[0]?.payment_mode" md="12" class="text-center"
                  ><span
                    class="text-h4 px-2 font-weight-bold mb-2 text-uppercase bg-color"
                    style="
                      color: #24326d !important;
                      background: #dbdbed;
                      border: 3px solid rgb(36, 50, 109);
                    "
                    >{{ total[0]?.payment_mode }}</span
                  ></v-col
                >
                <v-col v-else md="12" class="text-center"
                  ><span
                    class="text-h4 px-2 font-weight-bold mb-2 text-uppercase bg-color"
                    style="
                      color: #24326d !important;
                      background: #dbdbed;
                      border: 3px solid rgb(36, 50, 109);
                    "
                    >{{ total[0]?.supplier.display_name }}
                  </span></v-col
                >
                <v-col md="12" class="py-1">
                  <table class="table table-sm mb-0" width="100%">
                    <thead class="table-light">
                      <tr>
                        <th>PO #</th>
                        <th class="text-center">Payment To</th>
                        <!-- <th class="text-center">Primary Payment</th>  -->
                        <th class="text-end">Amount (RM)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in total" :key="index" class="py-2">
                        <td>
                          <v-chip outlined label small color="primary">{{ row.barcode }}</v-chip>
                        </td>
                        <td v-if="!row.payment_mode" align="center">
                          {{ row.supplier.display_name }}
                        </td>
                        <td v-else align="center">{{ row.payment_mode }}</td>
                        <td align="end">{{ formatMoney(row.total) }}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="4" align="end">
                          <span class="text-h5 font-weight-bold"
                            >Total: {{ formatMoney(getTotal()) }}
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </v-col>
                <v-col md="6" class="py-1">
                  <label for="payment-made" class="field-label required"
                    >Primary Payment Made (RM)</label
                  >
                  <QuantityInput
                    hide-details
                    disabled
                    :loading="pageLoading"
                    id="payment-made"
                    placeholder="Payment Made"
                    v-model="paymentRecordData.payment_made"
                    class="mb-2"
                    prefixText="RM"
                  >
                  </QuantityInput>
                </v-col>
                <v-col md="6" class="py-1">
                  <label for="payment-number" class="field-label required">Payment</label>
                  <TextInput
                    hide-details
                    disabled
                    :loading="pageLoading"
                    id="payment-number"
                    placeholder="Payment Number"
                    v-model="paymentRecordData.payment_number"
                    class="mb-2"
                  >
                  </TextInput>
                </v-col>
                <v-col md="6" class="py-1">
                  <label for="payment-date" class="field-label required mb-1">Payment Date</label>
                  <DatePicker
                    hide-details
                    :disabled="pageLoading"

                    :loading="pageLoading"
                    id="payment-date"
                    v-model="paymentRecordData.payment_date"
                  ></DatePicker>
                </v-col>
                <v-col md="6" class="py-1">
                  <label for="payment-mode" class="field-label required">Payment Mode</label>
                  <TextInput
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    id="payment-mode"
                    v-model="paymentRecordData.payment_mode"
                    class="mt-0"
                  >
                  </TextInput>
                </v-col>
                <!-- <v-col md="6" class="py-1">
                  <label for="payment-through" class="field-label required">Paid Through</label>
                  <SelectInput
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    :items.sync="paymentThroughList"
                    id="payment-through"
                    v-model="paymentRecordData.payment_through"
                  >
                  </SelectInput>
                </v-col> -->
                <v-col md="6" class="py-1">
                  <label for="reference" class="field-label">Reference#</label>
                  <TextInput
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    id="reference"
                    placeholder="Reference"
                    v-model="paymentRecordData.reference"
                    class="mb-2"
                  >
                  </TextInput>
                </v-col>
                <v-col md="12" class="py-1">
                  <label for="notes" class="field-label">Notes</label>
                  <TextAreaInput
                    v-model="paymentRecordData.payment_notes"
                    auto-grow
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    id="notes"
                    placeholder=""
                  ></TextAreaInput>
                </v-col>
                <!-- <v-col md="12" class="py-1">
                  <label for="attachments" class="field-label">Attachments</label>

                  <PurchaseDocument
                    id="file"
                    :directionColumn="false"
                    v-on:uploadData="test($event)"
                    allowAddMore
                    v-model="paymentRecordData.attachments"
                  ></PurchaseDocument>
                </v-col> -->
                <v-col md="12" class="py-1">
                  <v-divider></v-divider>
                  <v-checkbox
                    v-model="paymentRecordData.notification_checkbox"
                    label="Send a Payment Made email notification."
                  ></v-checkbox>
                  <v-divider class="my-0"></v-divider>
                </v-col>
                <v-col md="12" v-if="paymentRecordData.notification_checkbox">
                  <div class="d-flex align-center">
                    <div class="grey lighten-3 me-2 px-1 py-1">
                      <v-checkbox
                        hide-details
                        class="my-0 py-0"
                        v-model="paymentRecordData.sendemail1"
                        label="ravi@gmail.com"
                      ></v-checkbox>
                    </div>
                    <div class="grey lighten-3 me-2 px-1 py-1">
                      <v-checkbox
                        hide-details
                        class="my-0 py-0"
                        v-model="paymentRecordData.sendemail2"
                        label="abc@gmail.com"
                      ></v-checkbox>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-layout>
          </template>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          :disabled="excelLoading"
          :loading="excelLoading"
          v-on:click="saveRecord()"
        >
          Save
        </v-btn>
        <v-btn
          class="ml-2"
          :disabled="excelLoading"
          depressed
          tile
          v-on:click="$emit('close', false)"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import PosMixin from "@/core/mixins/pos.mixin";
import QuantityInput from "@/view/components/QuantityInput";

//import FileUpload from "@/view/components/FileUpload";
import DatePicker from "@/view/components/DatePicker";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
//import SelectInput from "@/view/components/SelectInput";
// import PurchaseDocument from "@/view/components/PurchaseDocument";
import { CREATE_RECORD_BILL } from "@/core/lib/bill.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import MomentJS from "moment-timezone";

export default {
  mixins: [PosMixin],

  name: "item-dialog",
  title: "Dialog Item",
  data() {
    return {
      product: [],
      date_bill: MomentJS().format("YYYY-MM-DD"),
      amount: 0,
      excelLoading: null,
      recordPaymentDialog: false,
      paymentThroughList: [
        { text: "Petty Cash", value: 1 },
        { text: "Undeposited Founds", value: 2 },
      ],
      paymentModeList: [
        { text: "Cash", value: 1 },
        { text: "Cheque", value: 2 },
      ],
      paymentRecord: [
        {
          po_number: "PO0001",
          venderName: "business thrust techsoft pvt ltd",
          primaryPayment: "",
          amount: 309.88,
        },
      ],
      paymentRecordData: {
        payment_made: 309.88,
        payment_number: "1",
        payment_date: "",
        payment_mode: "Bank Transfer",
        reference: "",
        payment_notes: "",
        attachments: [],
        barcode: null,
        notification_checkbox: false,
        sendemail1: true,
        sendemail2: false,
      },
    };
  },
  methods: {
    async saveRecord() {
      this.paymentRecordData.record = this.total;
      this.paymentRecordData.barcode = this.bills.bill_number;
      console.log(this.paymentRecordData, "datata");

      try {
        const customer = await CREATE_RECORD_BILL(this.paymentRecordData);
        this.$router.replace({
          name: "bills-listing",
          params: { id: customer.id },
          query: { t: new Date().getTime() },
        });
        this.$store.commit(SET_MESSAGE, [
          { model: true, message: " Record Payment  Created Successfully." },
        ]);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    getTotal() {
      this.product = this.total;

      let sum = 0;

      this.total?.forEach((element) => {
        sum = sum + Number(element.total);
      });
      this.amount = Number(sum);
      this.paymentRecordData.payment_made = this.amount;
      this.paymentRecordData.payment_number = this.product.length;

      return this.amount;
    },
  },
  // methods:{
  //   getTotal(){
  //          this.product = this.total

  // 			let sum = 0;

  // 			this.product?.forEach((element) => {

  // 				sum = sum + Number(element.total);
  // 			});
  //       console.log(sum)
  // 			this.total = Number(sum);
  // 			//  this.getDiscount();
  //       console.log(this.total_discount)
  //       this.$emit("input", this.total);
  // 			return this.total;

  //       },

  // },
  watch: {
    dialog(params) {
      this.recordPaymentDialog = params;
    },
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    total: {
      type: Array,
      default: () => {
        return [];
      },
    },
    bills: {
      type: Object,
      default: () => {
        return [];
      },
    },
  },
  components: {
    Dialog,
    //FileUpload,
    // PurchaseDocument,
    TextInput,
    DatePicker,
    TextAreaInput,
    //SelectInput,
    QuantityInput,
  },
};
</script>
<style scoped>
.table.table-sm td {
  padding: 0.6rem !important;
  vertical-align: middle;
}

.table.table-sm th {
  background-color: #ede8e8 !important;
}
</style>
